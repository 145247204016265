import * as React from "react"
import { graphql, Link } from "gatsby"
import { getImageData, StaticImage } from "gatsby-plugin-image"

import Seo from "../components/SEO"
import InfoBar from "../components/InfoBar"
import FooterSection from "../components/sections/FooterSection"
import ContactInfoContainer from "../components/containers/ContactInfoContainer"
import NewsletterContainer from "../components/containers/NewsletterContainer"
import EducationContainer from "../components/containers/EducationContainer"
import WorksContainer from "../components/containers/WorksContainer"
import RecommendationsContainer from "../components/containers/RecommendationsContainer"
import ServicesContainer from "../components/containers/ServicesContainer"
import ExperienceContainer from "../components/containers/ExperienceContainer"
import HeaderContainer from "../components/containers/HeaderContainer"
import Layout from "../components/Layout"
import { useTranslation } from "react-i18next"
import { usePageContext } from "../pageContext"

const IndexPage = ({ location, data }) => {

  const languageData = useTranslation()[1].store.data;

  const { lang } = usePageContext();

  const echo = (expression) => {
    return languageData[lang]['indexPage'][expression];
  }

  return (
    <React.Fragment>

      <Seo lang={lang} title={`Hanniel TSASSE - ${lang == 'fr' ? 'Développeur Web et Mobile' : 'Web & Mobile Developer'}`} description={echo('description')} />

      <Layout data={data} lang={lang}>

        {/* <!-- container --> */}
        <HeaderContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <ExperienceContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <ServicesContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <RecommendationsContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <WorksContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <EducationContainer lang={lang} />
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <NewsletterContainer lang={lang} />
        {/* <!-- container end --> */}

        <ContactInfoContainer lang={lang} />

      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }`