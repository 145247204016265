import React from 'react'
import { translate } from "react-i18next"
import { I18nextProvider, useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import Link from '../Link';

function HeaderContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['headerContainer'][expression];
    }

    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row p-60-0 p-lg-30-0 p-md-15-0">
                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- banner --> */}
                    <div className="art-a art-banner" style={{ backgroundImage: "url(/assets/img/bg.jpg)" }}>
                        {/* <!-- banner back --> */}
                        <div className="art-banner-back"></div>
                        {/* <!-- banner dec --> */}
                        <div className="art-banner-dec"></div>
                        {/* <!-- banner overlay --> */}
                        <div className="art-banner-overlay">
                            {/* <!-- main title --> */}
                            <div className="art-banner-title">
                                {/* <!-- title --> */}
                                <h1 className="mb-15">{echo("discover")}<br />{echo("discover2")}</h1>
                                {/* <!-- suptitle --> */}
                                <div className="art-lg-text art-code mb-25">&lt;<i>code</i>&gt; {echo("ibuild")} <span className="txt-rotate"
                                    data-period="2000"
                                    data-rotate={lang == 'fr' ? '[ "des Site Webs.", "des Applications Mobile", "des Appliations Web", "des Logiciels de bureau" ]' : '[ "Websites.", "Mobile Applications", "Web Appliations", "Desktop Apps" ]'}></span>&lt;/<i>code</i>&gt;</div>
                                <div className="art-buttons-frame">
                                    {/* <!-- button --> */}
                                    <Link title="Travaux de Hanniel TSASSE" to="/mes-travaux" className="art-btn art-btn-md"><span>{echo("explore")}</span></Link>
                                </div>
                            </div>
                            {/* <!-- main title end --> */}
                            {/* <!-- photo --> */}
                            <img src="/assets/img/face-2.png" className="art-banner-photo" alt="Your Name" />
                        </div>
                        {/* <!-- banner overlay end --> */}
                    </div>
                    {/* <!-- banner end --> */}

                </div>
                {/* <!-- col end --> */}
            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default HeaderContainer;
