import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import { Articles } from '../../providers/Articles'
import BlogCardItem from '../elements/BlogCardItem'

function NewsletterContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['newsletterContainer'][expression];
    }


    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row">

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{echo('new')}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- slider container --> */}
                    <div className="swiper-container art-blog-slider" style={{ overflow: 'visible' }}>
                        {/* <!-- slider wrapper --> */}
                        <div className="swiper-wrapper">
                            {Articles.map((article, index) => <BlogCardItem lang={lang} type='swipe' key={index} article={article} />)}
                        </div>
                        {/* <!-- slider wrapper end --> */}
                    </div>
                    {/* <!-- slider container end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- slider navigation --> */}
                    <div className="art-slider-navigation">

                        {/* <!-- left side --> */}
                        <div className="art-sn-left">

                            {/* <!-- slider pagination --> */}
                            <div className="swiper-pagination"></div>

                        </div>
                        {/* <!-- left side end --> */}

                        {/* <!-- right side --> */}
                        <div className="art-sn-right">

                            {/* <!-- slider navigation --> */}
                            <div className="art-slider-nav-frame">
                                {/* <!-- prev --> */}
                                <div className="art-slider-nav art-blog-swiper-prev"><i className="fas fa-chevron-left"></i></div>
                                {/* <!-- next --> */}
                                <div className="art-slider-nav art-blog-swiper-next"><i className="fas fa-chevron-right"></i></div>
                            </div>
                            {/* <!-- slider navigation --> */}

                        </div>
                        {/* <!-- right side end --> */}

                    </div>
                    {/* <!-- slider navigation end --> */}

                </div>
                {/* <!-- col end --> */}

            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default NewsletterContainer
