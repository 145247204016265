import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import Link from '../Link'

function ServicesContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['servicesContainer'][expression];
    }

    const echo_s = (index, expression) => {
        return languageData[lang]['servicesContainer']['services'][`${index}`][expression];
    }


    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row">

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{echo('my')} Services</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-4 col-md-6">

                    {/* <!-- service --> */}
                    <div className="art-a art-service-icon-box">
                        {/* <!-- service content --> */}
                        <div className="art-service-ib-content">
                            {/* <!-- title --> */}
                            <h5 className="mb-15">{echo_s(1, "title")}</h5>
                            {/* <!-- text --> */}
                            <div className="mb-15">{echo_s(1, "description")}</div>
                            {/* <!-- button --> */}
                            <div className="art-buttons-frame"><Link to="/contactez-moi"
                                className="art-link art-color-link art-w-chevron">{echo('command')}</Link></div>
                        </div>
                        {/* <!-- service content end --> */}
                    </div>
                    {/* <!-- service end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-4 col-md-6">

                    {/* <!-- service --> */}
                    <div className="art-a art-service-icon-box">
                        {/* <!-- service content --> */}
                        <div className="art-service-ib-content">
                            {/* <!-- title --> */}
                            <h5 className="mb-15">{echo_s(2, "title")}</h5>
                            {/* <!-- text --> */}
                            <div className="mb-15">{echo_s(2, "description")}</div>
                            {/* <!-- button --> */}
                            <div className="art-buttons-frame"><Link to="/contactez-moi"
                                className="art-link art-color-link art-w-chevron">{echo('command')}</Link></div>
                        </div>
                        {/* <!-- service content end --> */}
                    </div>
                    {/* <!-- service end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-4 col-md-6">

                    {/* <!-- service --> */}
                    <div className="art-a art-service-icon-box">
                        {/* <!-- service content --> */}
                        <div className="art-service-ib-content">
                            {/* <!-- title --> */}
                            <h5 className="mb-15">{echo_s(3, "title")}</h5>
                            {/* <!-- text --> */}
                            <div className="mb-15">{echo_s(3, "description")}</div>
                            {/* <!-- button --> */}
                            <div className="art-buttons-frame"><Link to="/contactez-moi"
                                className="art-link art-color-link art-w-chevron">{echo('command')}</Link></div>
                        </div>
                        {/* <!-- service content end --> */}
                    </div>
                    {/* <!-- service end --> */}

                </div>
                {/* <!-- col end --> */}

            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default ServicesContainer
